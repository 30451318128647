:local {
  .root {
    padding: 7em !important;

    h3 {
      font-family: 'ProximaNova Bold' !important;
      font-size: 1.5em !important;
    }

    p {
      font-size: 1.33em !important;
      font-family: 'ProximaNova' !important;
    }

    .list {
      padding: 3em;
      font-family: 'ProximaNova' !important;
    }
  }
}
