:local {
  .root {
    font-family: 'ProximaNova Bold' !important;
    padding: 5em 0 !important;

    h2 {
      font-size: 1.5em;
      font-family: 'ProximaNova Bold' !important;
    }

    p {
      font-size: 1.33em !important;
      font-family: 'ProximaNova' !important;
      text-align: justify;
    }
  }
}
