:local {
  .root {
    padding: 8em 0 !important;

    .imageGroup {
      img {
        padding: 2em;
      }
    }
  }
}
