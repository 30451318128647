:local {
  .root {
    padding: 5em 0 !important;

    h3 {
      font-family: 'ProximaNova Bold' !important;
      font-size: 1.5em !important;
    }

    p {
      font-size: 1.33em !important;
      font-family: 'ProximaNova' !important;
    }

    .container {
      box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 4px;
      opacity: 0.7;
      padding: 1.5em;
      font-family: 'ProximaNova' !important;
    }

    .divider {
      margin: 3em 0 !important;
      text-transform: uppercase !important;
    }

    .list {
      .listHeader {
        font-family: 'ProximaNova' !important;
      }
    }
  }
}
