:local {
  .menuContainer {
    font-family: 'ProximaNova Bold' !important;
  }
  .headerContainer {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px;
    margin: 0 auto;
    opacity: 0.8;
    padding: 1.5em;
    width: 457px !important;

    h2 {
      font-family: 'ProximaNova Bold' !important;
      font-size: 1.5em !important;
    }

    p {
      font-size: 1.33em !important;
      font-family: 'ProximaNova' !important;
      text-align: center;
    }
  }

  .footer {
    padding: 5em 0 !important;

    h2 {
      font-family: 'ProximaNova Bold' !important;
    }

    .listContent {
      font-family: 'ProximaNova' !important;
    }
  }
}
