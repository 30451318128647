body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/ProximaNova-Regular.eot');
  src: url('./fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/ProximaNova-Regular.woff') format('woff'), url('./fonts/ProximaNova-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ProximaNova Bold';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/ProximaNova-Bold.eot');
  src: url('./fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/ProximaNova-Bold.woff') format('woff'), url('./fonts/ProximaNova-Bold.ttf') format('truetype');
}
